import { Box } from '@chakra-ui/react';
import ReferralsData from './ReferralsData';

const UserReferrals = ({ userId }) => {
  return (
    <Box>
      {userId && (
        <ReferralsData
          type='modal'
          userId={userId}
        />
      )}
    </Box>
  );
};

export default UserReferrals;
