import { useRef } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, Text } from '@chakra-ui/react';
import { CloseModal } from 'components';
import ReferralsData from 'pages/Users/ReferralsData';

const UserReferralsModal = ({ fullName, userId, isOpen, onClose }) => {
  const initialRef = useRef(null);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior='inside'
      >
        <ModalOverlay />

        <ModalContent
          borderRadius='16px'
          maxWidth='1024px'
          padding={{ base: '32px 4px', md: '32px 0' }}
          height='90vh'
          overflowY='scroll'
        >
          <ModalHeader
            fontWeight='600'
            textAlign='center'
            p='0'
            mb='24px'
            textTransform='capitalize'
          >
            <Text as='span'>{fullName}</Text>'s Referrals
          </ModalHeader>

          <ModalBody
            ref={initialRef}
            scrollBehavior='smooth'
          >
            <ReferralsData
              type='new_tab'
              userId={userId}
            />

            <CloseModal onClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserReferralsModal;
