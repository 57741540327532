import { Text, Table, Thead, Tr, Th, Tbody, Td, Box, Flex } from '@chakra-ui/react';
import { CopyIcon, CheckIcon } from '@chakra-ui/icons';
import { Loader, ScrollRightContainer } from 'components';
import { useCopy } from 'hooks';

const UserCoinWallet = ({ wallet }) => {
  const { data, status } = wallet;
  console.log(data, 'THE __ WALLET DATA');

  const { hasCopied, onCopy, value } = useCopy();
  return (
    <ScrollRightContainer>
      {status === 'loading' && <Loader />}

      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Coins</Th>
            <Th>Available Coins</Th>
            <Th>Address</Th>
            <Th>Last Deposit</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.wallet.map(({ _id, coin, balance, address, v2, v3, lastDeposit, memo, tagNumber }, i) => {
            return (
              <Tr key={_id}>
                <Td>{i + 1}</Td>

                <Td
                  fontWeight='500'
                  textTransform='uppercase'
                >
                  {coin}
                </Td>

                <Td>
                  <Text
                    as='span'
                    className='amount'
                    maxW={'20px'}
                  >
                    {balance ? Number(balance?.$numberDecimal).toLocaleString() : ''}
                  </Text>
                  &nbsp;
                  <Text
                    as='span'
                    textTransform='uppercase'
                  >
                    {coin}
                  </Text>
                </Td>

                <Td wordBreak={'break-all'}>
                  <Box width='100%'>
                    <Box
                      borderBottom={v2 ? '0.25px solid #E2E8F0' : 'none'}
                      mb='4px'
                      pb='4px'
                    >
                      {address ? (
                        <Flex
                          gridGap='4px'
                          justifyContent='space-between'
                          mb='2px'
                        >
                          <Box>
                            <Text
                              as='span'
                              fontWeight='500'
                            >
                              v1:{' '}
                            </Text>
                            {address}
                          </Box>

                          <Box
                            width='25px'
                            cursor='pointer'
                            onClick={() => onCopy(address)}
                          >
                            {hasCopied && value === address ? <CheckIcon /> : <CopyIcon />}
                          </Box>
                        </Flex>
                      ) : (
                        'N/A'
                      )}

                      {memo ? (
                        <Flex
                          gridGap='4px'
                          alignItems='center'
                          onClick={() => onCopy(memo)}
                          cursor='pointer'
                        >
                          <Text
                            as='span'
                            fontWeight='500'
                          >
                            Memo:
                          </Text>
                          {memo}
                          {hasCopied && value === memo ? <CheckIcon /> : <CopyIcon />}
                        </Flex>
                      ) : (
                        ''
                      )}
                      {tagNumber ? (
                        <Flex
                          gridGap='4px'
                          alignItems='center'
                          onClick={() => onCopy(tagNumber)}
                          cursor='pointer'
                        >
                          <Text
                            as='span'
                            fontWeight='500'
                          >
                            Tag Number:
                          </Text>
                          {tagNumber}
                          {hasCopied && value === tagNumber ? <CheckIcon /> : <CopyIcon />}
                        </Flex>
                      ) : (
                        ''
                      )}
                    </Box>

                    {v2 ? (
                      <Box>
                        <Flex
                          gridGap='4px'
                          justifyContent='space-between'
                          mb='2px'
                        >
                          <Box>
                            <Text
                              as='span'
                              fontWeight='500'
                            >
                              v2:{' '}
                            </Text>
                            {v2.address}
                          </Box>

                          <Box
                            width='25px'
                            cursor='pointer'
                            onClick={() => onCopy(v2.address)}
                          >
                            {hasCopied && value === v2.address ? <CheckIcon /> : <CopyIcon />}
                          </Box>
                        </Flex>

                        {v2.memo ? (
                          <Flex
                            gridGap='4px'
                            alignItems='center'
                            onClick={() => onCopy(v2.memo)}
                            cursor='pointer'
                          >
                            <Text
                              as='span'
                              fontWeight='500'
                            >
                              Memo:
                            </Text>
                            {v2.memo}
                            {hasCopied && value === v2.memo ? <CheckIcon /> : <CopyIcon />}
                          </Flex>
                        ) : (
                          ''
                        )}
                        {v2.tagNumber ? (
                          <Flex
                            gridGap='4px'
                            alignItems='center'
                            onClick={() => onCopy(v2.tagNumber)}
                            cursor='pointer'
                          >
                            <Text
                              as='span'
                              fontWeight='500'
                            >
                              Tag Number:
                            </Text>
                            {v2.tagNumber}
                            {hasCopied && value === v2.tagNumber ? <CheckIcon /> : <CopyIcon />}
                          </Flex>
                        ) : (
                          ''
                        )}
                      </Box>
                    ) : (
                      ''
                    )}

                    {v3 ? (
                      <Box>
                        <Flex
                          gridGap='4px'
                          justifyContent='space-between'
                          mb='2px'
                        >
                          <Box>
                            <Text
                              as='span'
                              fontWeight='500'
                            >
                              v3:{' '}
                            </Text>
                            {v3.address}
                          </Box>

                          <Box
                            width='25px'
                            cursor='pointer'
                            onClick={() => onCopy(v3.address)}
                          >
                            {hasCopied && value === v3.address ? <CheckIcon /> : <CopyIcon />}
                          </Box>
                        </Flex>

                        {v3.memo ? (
                          <Flex
                            gridGap='4px'
                            alignItems='center'
                            onClick={() => onCopy(v3.memo)}
                            cursor='pointer'
                          >
                            <Text
                              as='span'
                              fontWeight='500'
                            >
                              Memo:
                            </Text>
                            {v3.memo}
                            {hasCopied && value === v3.memo ? <CheckIcon /> : <CopyIcon />}
                          </Flex>
                        ) : (
                          ''
                        )}
                        {v3.tagNumber ? (
                          <Flex
                            gridGap='4px'
                            alignItems='center'
                            onClick={() => onCopy(v3.tagNumber)}
                            cursor='pointer'
                          >
                            <Text
                              as='span'
                              fontWeight='500'
                            >
                              Tag Number:
                            </Text>
                            {v3.tagNumber}
                            {hasCopied && value === v3.tagNumber ? <CheckIcon /> : <CopyIcon />}
                          </Flex>
                        ) : (
                          ''
                        )}
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </Td>

                <Td>{lastDeposit ? Number(lastDeposit).toLocaleString() : 'N/A'}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </ScrollRightContainer>
  );
};

export default UserCoinWallet;
